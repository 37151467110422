<template>
  <div class="helpCenterPage">
    <div class="content_area">
      <p class="pageTitle">SOP of Login and Console</p>
      <div class="mainVideo">
        <AliyunVideo
          :AliyunId="mainVideoId"
          class="mainVideoContent"
          :autoplay="false"
        ></AliyunVideo>
      </div>
      <div class="pageCenterDriver" v-if="videoList.length">
        <p class="pagesumTitle">
          {{ pageSumTitle }}
          <span>{{ pageSumTitleSmall }}</span>
        </p>
      </div>
      <div class="videoList clearfix">
        <div
          class="video_item fl"
          v-for="(item, index) in videoList"
          :key="index"
          @click="playVideo(item.aliyun_id)"
        >
          <div class="imgPanel">
            <al-image :src="item.cover_img" fit="cover"></al-image>
          </div>
          <div class="textPanel textOverflow" :title="item.video_name">
            {{ item.video_name }}
          </div>
        </div>
      </div>
      <videoPlay ref="playVideo"></videoPlay>
    </div>
  </div>
</template>

<script>
import AliyunVideo from "~/baseComponents/AliyunVideo";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  components: { AliyunVideo, videoPlay },
  mounted() {
    // this.mainVideoId = this[`mainVideoId_${this.LOCALE}`];
  },
  data() {
    return {
      mainVideoTitle: this.$t("quicklyKnowExpo"),
      mainVideoId: "4761257e84174ee9a8eb8c1d2be66999",
      pageSumTitle: this.$t("playroundExpo"),
      pageSumTitleSmall: this.$t("updateanytime"),
      videoList: [
        // {
        //   aliyun_id: "f2910721892d4bcea23058547dab36f4",
        //   cover_img:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/helpCenter/1v1.png",
        //   video_name: "如何进行1v1洽谈",
        // },
      ],
    };
  },
  methods: {
    playVideo(id) {
      this.$refs.playVideo.startPlay(id);
    },
  },
};
</script>

<style scoped lang="less">
.helpCenterPage {
  padding: 30px 0px;
  background: #f6f7f9;
  .pageTitle {
    font-size: 24px;
    font-weight: 800;
    color: #333333;
    line-height: 33px;
    margin-bottom: 16px;
  }
  .mainVideo {
    width: 1300px;
    height: 614px;
    border: 1px solid #e9e9e9;
  }
  .pagesumTitle {
    font-size: 18px;
    font-weight: 500;
    color: #1f292e;
    margin-top: 35px;
    margin-bottom: 32px;
    span {
      position: relative;
      top: 2px;
      font-size: 14px;
      color: #999999;
    }
  }
  .videoList {
    .video_item {
      margin-right: 20px;
      margin-bottom: 16px;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0px;
      }
      .imgPanel {
        width: 310px;
        height: 175px;
      }
      .textPanel {
        width: 310px;
        height: 52px;
        line-height: 52px;
        border: 1px solid #e9e9e9;
        padding-left: 16px;
      }
    }
  }
}
</style>